import React, { useRef, useState } from "react";
import Container from "../../../components/container";
// import { ExclamationCircleIcon } from "@heroicons/react/solid";
import RadioButtons from "../../../components/RadioButtons";
import { MediaVideo } from "../../../components/MediaVideo";
import { navigate } from "gatsby";

export default function Index() {
  const [showResponse, setShowResponse] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [points, setPoints] = useState(0);
  const rootRef = useRef();

  const responseRef = useRef();
  let firstQuestion = "question1";
  const [currentQuestion, setCurrentQuestion] = useState(firstQuestion);
  const [response, setResponse] = useState();
  const questions = {
    question1: {
      intro: "La primera decisión del día",
      description: "¿Qué desayuno?",
      imageSrc: "/te.jpg",
      title: "¿Café o té?",
      options: [
        {
          id: 1,
          points: 1,
          title: "Café",
          description: "",
          response: {
            title:
              "Obvio: Café solo, con leche y para los mas atrevidos con gotas.",
            media: () => <MediaVideo src="/fani-yes.mp4" />,
            description:
              "El primer chute del día, sobre todo para los que nos levantamos adormilados y sin ganas. Me encanta el olor a café por la mañana.",
          },
          submit: () => setCurrentQuestion("question2"),
        },
        {
          id: 2,
          points: 0,
          title: "Té",
          description: "",
          response: {
            title: "Aburrido y amargo",
            media: () => <MediaVideo src="/fani-no.mp4" />,
            description:
              "No apto para todos (astringente). Te puede jorobar el día. Además no es lo mismo 2 tazas de té que dos te tazas.",
          },
          submit: () => setCurrentQuestion("question2"),
        },
      ],
    },
    question2: {
      intro: "A la hora de vestir",
      description: "¿Qué ropa os gustaría llevar a una boda?",
      imageSrc: "/traje.jpg",
      title: "¿Chándal o traje?",
      options: [
        {
          id: 1,
          points: 1,
          title: "Chándal",
          description: "La elegancia no está reñida con la comodidad",
          response: {
            title: "No sólo los domingos",
            media: () => <MediaVideo src="/agus-yes.mp4" />,
            description:
              "No vale cualquier chándal, a mí nunca me veréis con uno del Real Madrid.",
          },
          submit: () => setCurrentQuestion("question3"),
        },
        {
          id: 2,
          points: 0,
          title: "Traje",
          description: "Por qué? No le veo el sentido.",
          response: {
            title: "Me visteis alguna vez con uno? Por algo será.",
            media: () => <MediaVideo src="/agus-no.mp4" />,
            description: "Cuenta la leyenda que una vez puse uno.",
          },
          submit: () => setCurrentQuestion("question3"),
        },
      ],
    },
    question3: {
      intro: "Hablemos de política ...",
      description: "Sé que lo estabais esperando",
      imageSrc: "/política.jpg",
      title: "¿Izquierda o derecha?",
      options: [
        {
          id: 1,
          points: 0,
          title: "Izquierda",
          description:
            "Perroflautas, veganos, feminazis, vagos, porreros, .....",
          response: {
            title: "Qué sois? podemitas desos!",
            media: () => <MediaVideo src="/carlos-no.mp4" />,
            description:
              "A que voy con el palo y os pego. Corred, corred que aún estáis a tiempo. ¡Rojos!",
          },
          submit: () => setCurrentQuestion("question4"),
        },
        {
          id: 2,
          points: 1,
          title: "Derecha",
          description: "Gente aseada, seria y que dice lo que piensa",
          response: {
            title: "¡Así me gusta!",
            media: () => <MediaVideo src="/carlos-yes.mp4" />,
            description:
              "Españoles de verdad, nos gusta ver los programas de Bertín Osborne, la misa los domingos, la siesta...",
          },
          submit: () => setCurrentQuestion("question4"),
        },
      ],
    },
    question4: {
      intro: "Ahora una preguntita existencial",
      description:
        "Personalmente, depende de qué esté antes en el pasillo del supermercado, pero...",
      imageSrc: "/huevo.jpg",
      title: "¿Qué fué antes, Huevo o Gallina?",
      options: [
        {
          id: 1,
          points: 1,
          title: "Huevo",
          description: "Frito, escalfado, cocido",
          response: {
            title: "Como es lógico",
            media: () => <MediaVideo src="/santa-yes.mp4" />,
            description: (
              <>
                <p className="mb-2">
                  El filósofo <strong>David Papineau</strong> proporciona una
                  solución se podría decir que <strong>semiótica</strong> al
                  problema: lo que define que un huevo sea de gallina es que
                  contenga dentro de sí un pollo o una gallina, pero su origen
                  puede ser cualquier otro.
                </p>
                <p className="mb-2">
                  “Diría que un huevo de gallina lo es si contiene una gallina”,
                  explica. “Si un canguro pusiese un huevo del que saliese un
                  avestruz, se trataría de un huevo de avestruz, no un huevo de
                  canguro. Siguiendo este razonamiento, la primera gallina tuvo
                  que salir de un huevo de gallina, incluso aunque ese huevo no
                  saliese de una gallina
                </p>
                <h4 className="text-center text-2xl font-bold my-4 ">
                  CLARO QUE ESTA EXPLICACIÓN ES MUCHO MÁS INTERESANTE
                </h4>
                <p className="mb-2">
                  Al parecer, el huevo lo puso una gallina, eso es obvio, pero
                  no una gallina cualquiera.
                </p>
                <p className="mb-2">
                  Así lo explica el profesor estadounidense Neil deGrasse Tyson
                  a la revista "Time". Fruto de su tesis, el divulgador ha
                  llegado a la conclusión de que lo primero ha sido el huevo ya
                  que, quien lo puso, fue una 'protogallina mutante'. ¡Ahí es
                  nada!.
                </p>
                <p className="mb-2">
                  Ahora bien, ¿qué hizo esa especie de ave?. Según la teoría del
                  profesor, el animal habría puesto un huevo diferente al resto:
                  una mutación genética que le daría una ventaja a la
                  descendencia de esta protogallina para adaptarse al medio.
                  Según profundiza el propio profesor, los pollos tienen una
                  proteína específica en sus ovarios que hace posible que se
                  forme la cáscara del huevo, motivo por el cual siempre se
                  pensó que lo primero había sido la gallina. Sin embargo, ahora
                  sabemos que la mutación genética repetida una y otra vez hizo
                  que finalmente naciera la primera gallina.
                </p>
                <p className="mb-2">Y sí, lo hizo de un huevo.</p>
                <p className="mb-2">El primero.</p>
              </>
            ),
          },
          submit: () => setCurrentQuestion("question5"),
        },
        {
          id: 2,
          points: 0,
          title: "Gallina",
          description: "Al ajillo, asada, en pepitoria",
          response: {
            title:
              "El razonamiento puede considerarse lógico, pero es equivocado",
            media: () => <MediaVideo src="/santa-no.mp4" />,
            description: (
              <>
                <p className="mb-2">
                  Científicos de la Universidad de{" "}
                  <strong>Warwick y Sheffield (Inglaterra)</strong> han
                  descubierto que{" "}
                  <i>
                    la gallina existió antes que el huevo ya que una proteína
                    hallada en los ovarios de las gallinas
                  </i>
                  , la "ovocledidina-17 (OC-17)" cumple una "función vital" en
                  la formación de la cáscara del huevo.
                </p>
                <h4 className="text-center text-2xl font-bold my-4 ">
                  PERO (PORQUE SIEMPRE HAY UN "PERO") UNO DE LOS EXPERTOS
                  AFIRMÓ:
                </h4>
                <p className="mb-2">
                  "Nuestro estudio nos hace detenernos por un momento y pensar
                  si el huevo del que salió la primera gallina fue realmente un
                  huevo de gallina", apunta el experto de la Universidad de
                  Warwick, Mark Rodger.
                </p>
                <h4 className="text-center text-2xl font-bold my-4 ">
                  AUNQUE NUNCA HAY QUE OLVIDAR LA <i>OPINIÓN</i> DE LOS
                  INTERESADOS
                </h4>
                <p className="mb-2 text-center">
                  <img
                    src="/gallo-rojo.jpeg"
                    alt="Gallo Rojo"
                    className="rounded"
                  />
                </p>
                <p className="mb-2">
                  <i>Yo puse el primer huevo de gallina</i> - Gallo rojo
                  prehistórico
                </p>
              </>
            ),
          },
          submit: () => setCurrentQuestion("question5"),
        },
      ],
    },
    question5: {
      intro: "En cuestión de comidas",
      description:
        "Todo el mundo tiene una comida favorita, pero hay verdades que son absolutas...",
      imageSrc: "/tortilla.png",
      title: "¿Qué es mejor, tortilla con o sin cebolla?",
      options: [
        {
          id: 1,
          points: 1,
          title: "Tortilla sin cebolla",
          description: "Es perfecta como es, no necesita aditivos.",
          response: {
            title: "Verdad absolutista",
            media: () => <MediaVideo src="/xabi-yes.mp4" />,
            description:
              "Es la única verdad absoluta del universo. Bueno, esto y que el chocolate con queso es delicioso. Y las pipas, no nos olvidemos de las pipas. ¿Alguien dijo aceitunas?",
          },
          submit: () => setCurrentQuestion("question6"),
        },
        {
          id: 2,
          points: 0,
          title: "Tortilla con cebolla",
          description: "La cebolla le da un toque más jugoso",
          response: {
            title: "No tenéis ni idea",
            media: () => <MediaVideo src="/xabi-no.mp4" />,
            description:
              "Bah, no merecéis ni la hora. No sé como podéis hacerle eso a una tortilla. El culmen de la felicidad, y la rellenáis con algo que solo sirve para hacer llorar.",
          },
          submit: () => setCurrentQuestion("question6"),
        },
      ],
    },
    question6: {
      intro: "Toca parrilla",
      description:
        "Tenemos todo listo, solo nos falta el churrasco. Pero oh... dilema",
      imageSrc: "/parrilla.webp",
      title: "¿Churrasco de ternera o de cerdo?",
      options: [
        {
          id: 1,
          points: 0,
          title: "Churrasco de cerdo",
          description: "Cerdos nos vamos a poner nosotros",
          response: {
            title: "Elección incorrecta",
            media: () => <MediaVideo src="/suso-no.mp4" />,
            description:
              "La guía Mi Michelín, descarta el uso del churrasco de cerdo por el exceso de huesos. Solo se acepta si asisten a la barbacoa cánidos varios.",
          },
          submit: () => setCurrentQuestion("question7"),
        },
        {
          id: 2,
          points: 1,
          title: "Churrasco de ternera",
          description: "¿Ternera viene de tierno no?",
          response: {
            title: "Elección correcta",
            media: () => <MediaVideo src="/suso-yes.mp4" />,
            description:
              "La guía Mi Michelín, sentencia que el churrasco de ternera poco hecho es un manjar, en una tarde de verano entre cervezas y amigos",
          },
          submit: () => setCurrentQuestion("question7"),
        },
      ],
    },
    question7: {
      intro: "Vacaciones",
      description: "Todos queremos unos días libres, pero ¿cuándo?",
      title: "¿verano o invierno?",
      imageSrc: "/verano-invierno.png",
      options: [
        {
          id: 1,
          points: 0,
          title: "Verano",
          description: "Playa, calor y gentes con poca ropa 😉",
          response: {
            title: "Coge vacaciones... si puedes...",
            media: () => <MediaVideo src="/sara-no.mp4" />,
            description: (
              <p>
                Solo algunos privilegiados pueden coger vacaciones en verano, y
                a eso hay que sumarle que viajar es más caro y que hay guiris
                ebrios everywhere. No nos olvidemos de la horrible sensación de
                salir de la ducha ya sudando, del hermoso posado de Ana Obregón
                que marca el inicio del verano ni de la maravillosa canción del
                verano que odias pero no puedes sacar de tu cabeza.
              </p>
            ),
          },
          submit: () => setCurrentQuestion("question8"),
        },
        {
          id: 2,
          points: 1,
          title: "Invierno",
          description: "Chimenea, abrigos y estar con la familia",
          response: {
            title: (
              <p>
                En invierno es <strong>Navidad</strong>. Punto.
              </p>
            ),
            media: () => <MediaVideo src="/sara-yes.mp4" />,
            description: (
              <>
                <p>
                  Para mi la Navidad es un argumento irrefutable. Pero puedo
                  convenceros con otras cosas como:
                  <ul className="list-disc pl-6">
                    <li>Chocolate con churros, mantita y peli</li>
                    <li>Juegos de mesa a la vera de la chimenea</li>
                    <li>Largos baños calentitos</li>
                    <li>Nieva, preciosa y divertida nieve</li>
                  </ul>
                </p>
                <p>Porque os había comentado que está la Navidad, ¿no?</p>
                <p className="mt-6">
                  La navidad <strong>MOLA</strong>
                </p>
              </>
            ),
          },
          submit: () => setCurrentQuestion("question8"),
        },
      ],
    },
    question8: {
      intro: "Hablemos de películas de culto...",
      description:
        "Cual de estas es, sin duda una película que no debería faltar en la vida de ninguna persona???",
      imageSrc: "/cameraman.png",
      title: "¿Qué película prefieres?",
      options: [
        {
          id: 1,
          points: 0,
          title: "2 Fast 2 Furious",
          description:
            "Da igual que ganes por un centímetro o por un kilómetro: ganar es ganar",
          response: {
            title: "No, no...",
            media: () => <MediaVideo src="/fatima-no.mp4" />,
            description: "Esta solo nos recuerda a Juli conduciendo",
            // "Coches tuneados, cachondas y malotes diciéndose jefadas... Todo el presupuesto invertido en lo que quieres ver, ni siquiera se preocupan del argumento. ¿Es la perfección?",
          },
          submit: () => setCurrentQuestion("question9"),
        },
        {
          id: 2,
          points: 1,
          title: "Los payasos asesinos del espacio exterior",
          description: "No vienen del circo pero si vienen con hambre",
          response: {
            title: "Si, si...",
            media: () => <MediaVideo src="/fatima-yes.mp4" />,
            description:
              "Una obra maestra de este calibre que nadie entiende que no haya ganado algún premio hasta ahora",
            // "Nos querían engañar con los payasos del circo, pero esta película viene a mostrarnos la realidad. Gracias Diego por sacarnos la venda de los ojos.",
          },
          submit: () => setCurrentQuestion("question9"),
        },
      ],
    },
    question9: {
      intro: "Tentempiés",
      description: "En las horas muertas, qué prefieres:",
      imageSrc: "/popcorn.png",
      title: "¿Olivas o Palomitas?",
      options: [
        {
          id: 1,
          points: 0,
          title: "Olivas",
          description: "Aceitunas, para el resto de mortales",
          response: {
            title: "¡Y escogéis oliva!",
            media: () => <MediaVideo src="/mirian-no.mp4" />,
            description:
              "La oliva... olivas rellenas de anchoas, con hueso, con jalapeños, machacadas, especiadas....entre la toma de decisión y la reducción de los envases...te acabas la cerveza antes de tomarlas",
          },
          submit: () => setCurrentQuestion("question10"),
        },
        {
          id: 2,
          points: 1,
          title: "Palomitas",
          description: "Revivamos la experiencia del cine",
          response: {
            title: "Esa es la idea",
            media: () => <MediaVideo src="/mirian-yes.mp4" />,
            description:
              "Oh sí, planazo!! Una buena peli, sofá, mantita y PALOMITAS!! Compartirlas o un bol cada uno...ya es cosa vuestra....",
          },
          submit: () => setCurrentQuestion("question10"),
        },
      ],
    },
    question10: {
      intro: "Lo importante en un matrimonio: la cama",
      description:
        "Se presupone que se va a compartir el lecho conyugal, pero...",
      imageSrc: "/cama.png",
      title: "¿Cómo preferís dormir realmente?",
      options: [
        {
          id: 1,
          points: 0,
          title: "Solo",
          description:
            "Esa sensación de moverte en el espacio infinito y que nadie te ronque al lado",
          response: {
            title: "¿Solo? Solo pensarlo me hace llorar",
            media: () => <MediaVideo src="/pegu-no.mp4" />,
            description:
              "De ninguna manera. Hay que consumar. ¿De dónde pensáis que salgo yo? ¿Tengo que explicároslo todo?",
          },
          submit: () => setShowResults(true),
        },
        {
          id: 2,
          points: 1,
          title: "Acompañado",
          description:
            "Nada como que te hagan cucharita y sentir el calor humano",
          response: {
            title: "¡¡Que guay tener algo que agarrar!!",
            media: () => <MediaVideo src="/pegu-yes.mp4" />,
            description:
              "La verdad, si te despiertas en medio de la noche no hay nada mejor que agarrar una teta.",
          },
          submit: () => setShowResults(true),
        },
      ],
    },
    question100: {
      intro: "Intro pregunta",
      description: "Descripción pregunta",
      imageSrc: "/parrilla.webp",
      title: "¿Churrasco de tenreira ou de porco?",
      options: [
        {
          id: 1,
          points: 0,
          title: "Opción 1 título",
          description: "Opción 1 descripción",
          response: {
            title: "Opción 1 respuesta - título",
            media: () => <MediaVideo src="/suso-no.mp4" />,
            description: "Opción 1 respuesta - descripción",
          },
          submit: () => setCurrentQuestion("question1"),
        },
        {
          id: 2,
          points: 0,
          title: "Opción 2 título",
          description: "Opción 2 descripción",
          response: {
            title: "Opción 2 respuesta - título",
            media: () => <MediaVideo src="/suso-yes.mp4" />,
            description: "Opción 2 respuesta - descripción",
          },
          submit: () => setCurrentQuestion("question1"),
        },
      ],
    },
  };
  console.log("res", currentQuestion, questions[currentQuestion], response);

  function handleNextQuestionSubmit() {
    setShowResponse(false);
    setPoints(points + response.points);
    response.submit();
    console.log("handleNextQuestionSubmit");
    rootRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }

  function handleQuestionSubmit(data) {
    setShowResponse(true);
    setResponse(data);
    console.log("handleQuestionSubmit");
    responseRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }

  function gotEnoughPoints() {
    return points >= 10;
  }

  const handleFinish = () => {
    if (gotEnoughPoints()) {
      navigate("/taza2/acertijo/taza");
    } else {
      setShowResults(false);
      setShowResponse(false);
      setPoints(0);
      setCurrentQuestion(firstQuestion);
    }
  };
  return (
    <Container>
      {showResults ? (
        <section
          ref={rootRef}
          className="flex-col flex items-center md:justify-between mt-16 md:mb-12"
        >
          <h1 className="text-4xl font-bold tracking-tighter leading-tight md:pr-8 text-center">
            Resultado final
          </h1>
          <h4 className="text-center md:text-left text-lg mt-5 md:pl-8">
            Veamos qué tal lo has hecho
          </h4>
          <p className="text-base text-lg mt-5">
            Has acertado {points} preguntas
          </p>
          <p className="text-base text-lg mt-5 text-center">
            {gotEnoughPoints()
              ? "Suficiente. Puedes seguir."
              : "Menudo desastre. Hala, a volver a empezar."}
          </p>
          <button
            type="submit"
            onClick={handleFinish}
            className=" my-6 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Siguiente
          </button>
        </section>
      ) : (
        <>
          <section
            ref={rootRef}
            className="flex-col flex items-center md:justify-between mt-16 md:mb-12"
          >
            <h1 className="text-4xl font-bold tracking-tighter leading-tight md:pr-8 text-center">
              {questions[currentQuestion].intro}
            </h1>
            <h4 className="text-center md:text-left text-lg mt-5 md:pl-8">
              {questions[currentQuestion].description}
            </h4>
          </section>
          <div ref={responseRef}></div>
          {!showResponse ? (
            <>
              <section className="flex-col  flex items-center md:justify-between mb-16 md:mb-12">
                <img
                  className="mt-5 rounded-2xl "
                  src={questions[currentQuestion].imageSrc}
                  title={questions[currentQuestion].title}
                  alt={questions[currentQuestion].title}
                />
              </section>
              <RadioButtons
                question={questions[currentQuestion]}
                onSubmit={handleQuestionSubmit}
              />
            </>
          ) : (
            <>
              <section className="flex-col flex items-center md:justify-between mb-16 md:mb-12">
                <p className="text-base text-lg mt-5">
                  {questions[currentQuestion].title}
                </p>

                <h4 className="text-base text-4xl font-bold tracking-tighter leading-tight mt-5 md:pl-8">
                  {response.title}
                </h4>
                <h5 className="text-base text-2xl tracking-tighter leading-tight mt-5 md:pl-8">
                  "{response.description}"
                </h5>
                <p className="text-base text-lg mt-5">
                  {response.response.title}
                </p>
                {response.response.media(response)}
                <p className="text-base text-lg mt-5">
                  {response.response.description}
                </p>
                <div className="w-full">
                  <button
                    type="submit"
                    onClick={handleNextQuestionSubmit}
                    className=" my-6 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Siguiente
                  </button>
                  {/*<button*/}
                  {/*  type="button"*/}
                  {/*  onClick={() => {*/}
                  {/*    setShowResponse(false);*/}

                  {/*    rootRef.current.scrollIntoView({*/}
                  {/*      behavior: "smooth",*/}
                  {/*      block: "start",*/}
                  {/*    });*/}
                  {/*  }}*/}
                  {/*  className=" my-6 ml-2 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"*/}
                  {/*>*/}
                  {/*  Repetir*/}
                  {/*</button>*/}
                </div>
              </section>
            </>
          )}
        </>
      )}
    </Container>
  );
}
